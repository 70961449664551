<template>
  <div class="card">
    <div class="card-body">
      <!-- <div class="d-flex justify-content-between align-items-center"> -->
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="card-title m-0">Top Viewed Newsletters</h4>
        <JsonExcel
          class="btn p-0"
          :data="newsletters"
          name="newsletter_top_viewed.csv"
          type="csv">
          <i class="mdi mdi-download"></i>
          Download
        </JsonExcel>
      </div>
      <!-- <div class="d-flex overall-count">
            <div class="mr-2">Overall Count</div>
            <switches v-model="overallCount" type-bold="true" color="success" class="m-0"></switches>
        </div> -->
      <!-- </div> -->
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="newsletters"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(state)="row">
            <div>{{ row.value != "" ? row.value : "Unknown" }}</div>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_pa ginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
      <!-- <template v-if="states != '' ">
        <div class="state-name-count" v-for="(state, index) in states" :key="index">
          <div class="name">{{ state.state }}</div>
          <div class="count">{{ state.total_count }}</div>
        </div>
      </template> -->
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";
import JsonExcel from "vue-json-excel";
// import Switches from "vue-switches";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    JsonExcel,
    // Switches,
  },
  data() {
    return {
      newsletters: [],
      overallCount: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "total_views",
      sortDesc: true,
      fields: [
        { key: "title", sortable: true, label: "Newsletters" },
        { key: "total_views", sortable: true, label: "Views" },
        { key: "total_viewers", sortable: true, label: "Viewers" },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getTopViewNewslettersList();
      },
    },
    overallCount() {
      this.getTopViewNewslettersList();
    },
  },
  computed: {
    rows() {
      return this.newsletters.length;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getTopViewNewslettersList() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        if (this.overallCount == true) {
          const response = await this.$http.get(
            appConfig.api_base_url + "/newsletters/views?overall=true",
            {
              params: searchfiltersParams,
            }
          );
          this.newsletters = response.data.newsletters;
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/newsletters/views",
            {
              params: searchfiltersParams,
            }
          );
          this.newsletters = response.data.newsletters;
        }
        this.totalRows = this.newsletters.length;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getTopViewNewslettersList();
  },
};
</script>
