<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Filters @filters="setFilters" />
    <div class="row">
      <div class="col-lg-2">
        <TotalNewsletterViews :filters="filters"></TotalNewsletterViews>
      </div>
      <div class="col-lg-10">
        <HourTrend :filters="filters"></HourTrend>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <TopViewedNewsletters :filters="filters"></TopViewedNewsletters>
      </div>
      <div class="col-lg-6">
        <TopSpecialities :filters="filters"></TopSpecialities>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <DayTrend :filters="filters"></DayTrend>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <CountryWiseCount :filters="filters"></CountryWiseCount>
      </div>
      <div class="col-lg-4">
        <StateWiseCount :filters="filters"></StateWiseCount>
      </div>
      <div class="col-lg-4">
        <CityWiseCount :filters="filters"></CityWiseCount>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <MonthTrend :filters="filters"></MonthTrend>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import TopSpecialities from "./TopSpecialities.vue";
import HourTrend from "./HourTrend.vue";
import DayTrend from "./DayTrend.vue";
import MonthTrend from "./MonthTrend.vue";
import CountryWiseCount from "./CountryWiseCount.vue";
import StateWiseCount from "./StateWiseCount.vue";
import CityWiseCount from "./CityWiseCount.vue";
import Filters from "../newsletters/filters.vue";
import TopViewedNewsletters from "./TopViewedNewsletters.vue";
import TotalNewsletterViews from "./TotalNewsletterViews.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Filters,
    TopSpecialities,
    HourTrend,
    TopViewedNewsletters,
    TotalNewsletterViews,
    CountryWiseCount,
    StateWiseCount,
    CityWiseCount,
    DayTrend,
    MonthTrend,
  },
  data() {
    return {
      title: "Newsletters",
      items: [
        {
          text: "Medisage",
        },
        {
          text: "Newsletters",
          active: true,
        },
      ],
      summary: {},
      previousSummary: {},
      state_summary: {},
      filters: {},
      reload: 0,
      params: {},
      loading: true,
    };
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    setFilters(v) {
      this.filters = v;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // this.init();
      },
    },
  },
};
</script>
