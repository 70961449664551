<template>
  <div>
    <div class="card">
      <div class="card-body pb-0 pt-2">
        <h4 class="card-title">Total Views</h4>
        <h3>{{ count.total_views }}</h3>
      </div>
      <div class="card-body border-top py-2">
        <div class="">
          <template v-if="statsData[0].subvalue">
            <span
              class="badge font-size-11"
              :class="
                statsData[0].subvalue > 0
                  ? 'badge-soft-success'
                  : 'badge-soft-danger'
              "
            >
              <i
                class="mdi"
                :class="
                  statsData[0].subvalue > 0 ? 'mdi-menu-up' : 'mdi-menu-down'
                "
              ></i>
              {{ statsData[0].subvalue }} %
            </span>
            <span class="text-muted ml-2">{{ statsData[0].subtext }}</span>
          </template>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body pb-0 pt-2">
        <h4 class="card-title">Total Viewers</h4>
        <h3>{{ count.total_viewers }}</h3>
      </div>
      <div class="card-body border-top py-2">
        <div class="">
          <template v-if="statsData[1].subvalue">
            <span
              class="badge font-size-11"
              :class="
                statsData[1].subvalue > 0
                  ? 'badge-soft-success'
                  : 'badge-soft-danger'
              "
            >
              <i
                class="mdi"
                :class="
                  statsData[1].subvalue > 0 ? 'mdi-menu-up' : 'mdi-menu-down'
                "
              ></i>
              {{ statsData[1].subvalue }} %
            </span>
            <span class="text-muted ml-2">{{ statsData[1].subtext }}</span>
          </template>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body pb-0 pt-2">
        <h4 class="card-title">Total Newsletters Published</h4>
        <h3>{{ count.newsletters_published }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  data() {
    return {
      count: 0,
      statsData: [
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getCount();
      },
    },
  },
  methods: {
    async getCount() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/newsletters/count",
          {
            params: searchfiltersParams,
          }
        );
        this.count = response.data;

        this.statsData[0].subvalue =
          this.count.previous_period_views !== 0
            ? (
                ((this.count.total_views - this.count.previous_period_views) /
                  this.count.previous_period_views) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[0].subvalue = !isNaN(this.statsData[0].subvalue)
          ? this.statsData[0].subvalue
          : "0";

        this.statsData[1].subvalue =
          this.count.previous_period_viewers !== 0
            ? (
                ((this.count.total_viewers -
                  this.count.previous_period_viewers) /
                  this.count.previous_period_viewers) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[1].subvalue = !isNaN(this.statsData[1].subvalue)
          ? this.statsData[1].subvalue
          : "0";
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getCount();
  },
};
</script>
